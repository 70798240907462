import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

class SubmitModal extends Component {
    state = {
        open: false
    };

    show = size => () => this.setState({ size, open: true });

    close = () => this.setState({ open: false });

    handlePositive = () => {
        const { onChangePositive } = this.props;
        this.setState({
            open: false
        });

        onChangePositive();
    };

    handleNegative = () => {
        const { onChangeNegative } = this.props;

        onChangeNegative();

        this.setState({
            open: false
        });
    };

    render() {
        const { open, size } = this.state;
        const {
            isDirty,
            title,
            type,
            disabled,
            content,
            onChangeNegative,
            loading
        } = this.props;

        return (
            <>
                <Button
                    onClick={isDirty ? this.show('tiny') : this.handlePositive}
                    positive={type === 'Save' || false}
                    disabled={disabled}
                    loading={loading}
                    icon
                    style={{ paddingLeft: '15px', paddingRight: '15px' }}
                    size='compact'
                    color={
                        type === 'Save' || type === 'Submit'
                            ? 'green'
                            : null
                    }>
                    {type}
                </Button>

                <Modal size={size} open={open} onClose={this.close}>
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Content>{content}</Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            onClick={onChangeNegative ? this.handleNegative : this.close}>
                            No
            </Button>
                        <Button positive onClick={this.handlePositive}>
                            Yes
            </Button>
                    </Modal.Actions>
                </Modal>
            </>
        );
    }
}
SubmitModal.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    onChangePositive: PropTypes.func.isRequired,
    onChangeNegative: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired
};

SubmitModal.defaultProps = {
    onChangeNegative: null,
    loading: false
};

export default SubmitModal;
