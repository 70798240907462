import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProgressiveImage from 'react-progressive-bg-image';
import { Grid, Image } from 'semantic-ui-react';

import img from '../images/courtroom2.jpg';
import logo from '../images/witcomNameLogo.png';

class PublicLayout extends Component {
  render() {
    const { component: Component, location, width, ...rest } = this.props;

    return (
      <>
        <ProgressiveImage
          delay={3000}
          src={img}
          placeholder={img}
          style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            resizeMode: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            OBackgroundSize: 'cover',
            backgroundSize: 'cover'
          }}>
            <div
            style={{
              marginLeft: '50px',
              marginTop: '40px',
              marginBottom:'0px',
              color: 'black',
              textAlign: 'left',
              position: 'absolute'
            }}>
            <Image src={logo} size='small'></Image>
            </div>
          <Grid centered style={{ height: '100vh' }}>
            <Grid.Column
              computer={width}
              mobile={12}
              stretched
              verticalAlign='middle'>
              <Component location={location} {...rest} />
            </Grid.Column>
          </Grid>
        </ProgressiveImage>
      </>
    );
  }
}

PublicLayout.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object,
  width: PropTypes.number
};

PublicLayout.defaultProps = {
  location: undefined,
  width: 4
};

export default PublicLayout;
