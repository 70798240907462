import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '../../services/RouteState';

import { graphql, withApollo } from 'react-apollo';
import { flowRight } from 'lodash';
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Message,
  Table
} from 'semantic-ui-react';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';

import {
  ListCases,
} from '../case/CaseApi';
import { AST_True } from 'terser';
import { isUndefined } from 'util';
import { isNullOrUndefined } from 'util';
import { getUserAttributes, getUserGroup } from '../../services/Authentication';
import UserContext from '../context/UserContext';

const TABLE_STYLE = { height: '78vh' };
const COLUMN_STYLE = { fontSize: '14px', border: 'none' };
const JURISDICTIONS = {
  'newyork-ny': 'New York',
  'kings-ny': 'Kings',
}

class IdentityTable extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null
    };
  }

  async componentDidMount() {
    const {
      defenderId,
      client: { query }
    } = this.props;

    try {
      const group = await getUserGroup();
      const attributes = await getUserAttributes();

      const cases = await query({
        query: ListCases,
      });
      this.setState({
        group: group,
        error: cases.error,
        loading: cases.loading,
        cases: cases.data.listCases,
      });
    } catch (error) {
      this.setState({
        error
      });
    }
    console.log('Cases State', this.state.cases)
  }

  handleClick = (id, active, status) => {
    console.log('id', id, 'active', active, 'status', status);
    navigate('/app/case/view', {
      state: {
        caseId: id,
        active: active,
        status: status,
        pathFrom: '/defender/view'
      }
    });
  };

  render() {
    const { error, loading } = this.props.data;
    const { group, cases } = this.state;
    const filters = [];

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        <Header>Cases</Header>
        {cases && (
          <Dimmer.Dimmable dimmed={loading}>
            <ReactTable
              filterable
              data={cases.items}
              style={TABLE_STYLE}
              defaultPageSize={10}
              className='-highlight'
              showPageSizeOptions={false}
              noDataText='No Case Data Available'
              defaultSorted={[
                {
                  id: "caseNumber",
                  desc: false
                }
              ]}
              defaultFiltered={[
                {
                  id: 'status',
                  value: 'all'
                },
                {
                  id: 'assigned',
                  value: 'true'
                }
              ]}
              defaultFilterMethod={(filter, row, column) => {
                const key = filter.id;
                const value = filter.value;
                return row[key] === value;
              }}
              columns={[
                {
                  filterAll: true,
                  filterable: true,
                  style: COLUMN_STYLE,
                  id: 'caseNumber',
                  Header: 'Arrest ID',
                  accessor: row => row.case.caseNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, { keys: ['caseNumber'] })
                },
                {
                  filterAll: true,
                  filterable: true,
                  style: COLUMN_STYLE,
                  id: 'matterNumber',
                  Header: 'Docket No.',
                  accessor: row => row.case.matterNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, { keys: ['matterNumber'] }),
                  Cell: ({ original }) => {
                    return <span>{original.case.matterNumber || '-'}</span>;
                  }
                },
                {
                  filterAll: true,
                  style: COLUMN_STYLE,
                  id: 'indictmentNumber',
                  Header: 'Indictment No.',
                  accessor: row => row.case.indictmentNumber,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, {
                      keys: ['indictmentNumber']
                    }),
                  Cell: ({ original }) => {
                    return <span>{original.case.indictmentNumber || '-'}</span>;
                  }
                },
                {
                  filterAll: true,
                  id: 'defendantName',
                  Header: 'Defendant',
                  style: COLUMN_STYLE,
                  accessor: row =>
                    `${row.case.defendantLastName}, ${row.case.defendantFirstName}`,
                  filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {
                      keys: ['defendantName']
                    })
                },
                {
                  filterAll: true,
                  style: COLUMN_STYLE,
                  id: 'jurisdiction',
                  Header: 'County',
                  accessor: row => JURISDICTIONS[row.case.jurisdiction] || row.case.jurisdiction,
                  filterMethod: (filter, row) =>
                    matchSorter(row, filter.value, {
                      keys: ['jurisdiction']
                    }),
                },
                {
                  // this references .case.status on the current item
                  // the value means case open vs case closed
                  // it comes from the 'status' column from case-service-case in dynamodb
                  // valid values are 'active' and 'closed'
                  id: 'status',
                  sortable: false,
                  filterAll: false,
                  filterable: true,
                  style: COLUMN_STYLE,
                  Header: 'Case Status',
                  accessor: row => row.case.status,
                  Cell: ({ original }) => {
                    if (original.case.status === 'active') {
                      return <span>Open</span>;
                    }
                    return <span>Closed</span>;
                  },
                  filterMethod: (filter, row) => {
                    switch (filter.value) {
                      case 'active':
                        return row.status === 'active';
                      case 'closed':
                        return row.status === 'closed';
                      default:
                        // case all
                        return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      value={filter.value}
                      style={{ height: '28px', width: '100%' }}
                      onChange={event => onChange(event.target.value)}
                    >
                      <option value='active'>Open</option>
                      <option value='closed'>Closed</option>
                      <option value='all'>All</option>
                    </select>
                  )
                },
                {
                  // this references .active on the current item
                  // the value means that the defense attorney is assigned or removed from the case
                  // it comes from the 'active' column from case-service-identity in dynamodb
                  // valid values are true and false
                  id: 'assigned',
                  sortable: false,
                  filterAll: false,
                  filterable: true,
                  style: COLUMN_STYLE,
                  Header: 'Assignment Status',
                  accessor: row => row.active,
                  Cell: ({ original }) => {
                    if (original.active === true) {
                      return <span>Assigned to Case</span>;
                    }
                    return <span>Removed from Case</span>;
                  },
                  filterMethod: (filter, row) => {
                    switch (filter.value) {
                      case 'true':
                        return row._original.active === true;
                      case 'false':
                        return row._original.active === false;
                      default:
                        // case all
                        return true;
                    }
                  },
                  Filter: ({ filter, onChange }) => {
                    return (
                      <select
                        value={filter.value}
                        style={{ height: '28px', width: '100%' }}
                        onChange={event => onChange(event.target.value)}
                      >
                        <option value='true'>Assigned to Case</option>
                        <option value='false'>Removed from Case</option>
                        <option value='all'>All</option>
                      </select>
                    );
                  }
                },
                {
                  id: 'home',
                  Header: '',
                  accessor: row => row,
                  sortable: false,
                  filterable: true,
                  style: { textAlign: 'center' },
                  Cell: ({ value }) => (
                    <div>
                      <Button
                        compact
                        size='small'
                        color='teal'
                        onClick={() => this.handleClick(value.case.id, value.active, value.case.status)}
                      >
                        Case Home
                        </Button>
                    </div>
                  ),
                  Filter: ({ filter, onChange }) => (
                    <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                      <i aria-hidden='true' className='search icon' />
                      Column Filters
                    </span>
                  )
                }
              ]}
            />
            <Dimmer active={loading} inverted>
              <Loader />
            </Dimmer>
          </Dimmer.Dimmable>
        )}
      </>
    );
  }
}

IdentityTable.propTypes = {
  data: PropTypes.object,
  deleteCase: PropTypes.func
};

IdentityTable.defaultProps = {
  data: {}
};

export default withApollo(IdentityTable);
