import * as Cognito from 'amazon-cognito-identity-js';

// These are not considered secret by AWS and can be exposed in the frontend.
// AWS manages access and verification with the Cognito client pools.
const cognitoUserPool = new Cognito.CognitoUserPool({
  UserPoolId: process.env.COGNITO_USERPOOL_ID,
  ClientId: process.env.COGNITO_CLIENT_ID
});

const isAuthenticated = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        if (error) {
          console.log('Session error', error);

          reject();
        } else if (!session.isValid()) {
          console.log('Session not valid');
          reject();
        } else {
          console.log('You shall pass.');
          resolve();
        }
      });
    } else {
      console.log('No user found');

      reject();
    }
  });

const getAccessToken = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject(error);
        } else if (!session.isValid()) {
          reject();
        } else {
          resolve(session.getAccessToken().getJwtToken());
        }
      });
    } else {
      reject();
    }
  });

const registerUser = ({ email, password, firstName, lastName, phoneNumber }) =>
  new Promise((resolve, reject) => {
    const attributes = [
      {
        Name: 'email',
        Value: email
      },
      {
        Name: 'given_name',
        Value: firstName
      },
      {
        Name: 'family_name',
        Value: lastName
      },
      {
        Name: 'phone_number',
        Value: phoneNumber
      }
    ];

    cognitoUserPool.signUp(
      email,
      password,
      attributes,
      null,
      (error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      }
    );
  });

const confirmRegistration = ({ email, confirmationCode }) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new Cognito.CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });

    cognitoUser.confirmRegistration(confirmationCode, true, (error, result) => {
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
    });
  });

const authenticateUser = ({ email, password, newPassword }) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new Cognito.CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });

    // This method is called for login and new password cases. Cognito's
    // authenticateUser returns newPasswordRequired with a set of attributes
    // used to challenge the response.

    const authenticationDetails = new Cognito.AuthenticationDetails({
      Username: email,
      Password: password
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        resolve(result);
      },
      onFailure: error => {
        reject(error);
      },
      newPasswordRequired: userAttributes => {
        if (!newPassword) {
          reject({
            code: 'NewPasswordRequired',
            name: 'NewPasswordRequired',
            message: 'Password change required.'
          });
        } else {
          delete userAttributes.email_verified; // the api doesn't accept the email verified field back

          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: result => {
                resolve(result);
              },
              onFailure: error => {
                reject(error);
              }
            }
          );
        }
      }
    });
  });

const getSessionToken = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject();
        } else if (!session.isValid()) {
          reject();
        } else {
          cognitoUser.getUserAttributes((error, userAttributes) => {
            if (error) {
              reject();
            } else {
              resolve(
                userAttributes.reduce((result, attribute) => {
                  let name = attribute.Name;
                  if (name.startsWith('custom:')) {
                    name = name.substring(7);
                  }
                  return session;
                }, {})
              );
            }
          });
        }
      });
    } else {
      reject();
    }
  });

const getUserGroup = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject();
        } else if (!session.isValid()) {
          reject();
        } else {
          cognitoUser.getUserAttributes((error, userAttributes) => {
            if (error) {
              reject();
            } else {
              resolve(
                userAttributes.reduce((result, attribute) => {
                  let name = attribute.Name;
                  if (name.startsWith('custom:')) {
                    name = name.substring(7);
                  }
                  return session.idToken.payload['cognito:groups'][0];
                }, {})
              );
            }
          });
        }
      });
    } else {
      reject();
    }
  });

const getUserAttributes = async () =>
  new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((error, session) => {
        if (error) {
          reject();
        } else if (!session.isValid()) {
          reject();
        } else {
          cognitoUser.getUserAttributes((error, userAttributes) => {
            if (error) {
              reject();
            } else {
              resolve(
                userAttributes.reduce((result, attribute) => {
                  let name = attribute.Name;
                  if (name.startsWith('custom:')) {
                    name = name.substring(7);
                  }
                  result[name] = attribute.Value;
                  return result;
                }, {})
              );
            }
          });
        }
      });
    } else {
      console.log('Failing here');
      reject();
    }
  });

const confirmPassword = ({ email, verificationCode, newPassword }) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new Cognito.CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: result => {
        resolve(result);
      },
      onFailure: error => {
        reject(error);
      }
    });
  });

const forgotPassword = ({ email }) =>
  new Promise((resolve, reject) => {
    const cognitoUser = new Cognito.CognitoUser({
      Username: email,
      Pool: cognitoUserPool
    });

    cognitoUser.forgotPassword({
      onSuccess: result => {
        resolve(result);
      },
      onFailure: error => {
        reject(error);
      }
    });
  });

const signOut = () =>
  new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    }
    resolve(true);
  });

export {
  authenticateUser,
  confirmPassword,
  confirmRegistration,
  forgotPassword,
  getAccessToken,
  getSessionToken,
  getUserAttributes,
  getUserGroup,
  isAuthenticated,
  registerUser,
  signOut
};
