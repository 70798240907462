import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { flowRight } from 'lodash';
import { Dimmer, Loader, Message, Table, Icon, Button, Label } from 'semantic-ui-react';
import ConfirmModal from '../modal/ConfirmModal';
import moment from 'moment';
import { getUserAttributes } from '../../services/Authentication';
import { formatPhoneNumber } from 'react-phone-number-input';
import { ListWitnesses, CallWitness } from './WitnessApi';

class WitnessTable extends Component {

  componentWillMount() {
    this.setState({});
  };

  async componentDidMount() {
    const {
      caseId,
      active,
      status,
      data: { subscribeToMore }
    } = this.props;
    const attributes = await getUserAttributes();
    this.setState({
      // proxy: attributes.proxy,
      formattedProxy: this.formatPhoneDisplay(attributes.proxy),
      active: active,
      status: status
    });
  }

  placeCall = async witness => {
    try {

      const { callWitness } = this.props;

      this.setState({
        waiting: true
      });

      const response = await callWitness({
        caseId: witness.caseId,
        witnessId: witness.id
      });

      this.setState({
        waiting: false
      });

      if (response.data && response.data.callWitness) {
        // call({
        //   number: response.data.callWitness.phoneNumber,
        //   prompt: true
        // });
      } else {
        const error =
          response.errors && response.errors.length > 0
            ? response.errors[0].message
            : 'No outbound number is available.';
        const message = `${error} Please try again later.`;

        // showMessage({
        //   duration: 4000,
        //   floating: true,
        //   icon: 'warning',
        //   message: 'Unavailable',
        //   description: message,
        //   type: 'warning'
        // });
      }
    } catch (err) {
      console.error('WitnessContact callWitness error:', err);
    }
  };

  upperCase = string => string.charAt(0).toUpperCase() + string.slice(1);

  formatPhoneDisplay = text => {
    const removedPlus = text.replace('+1', '');
    const cleaned = removedPlus.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = cleaned.substring(0, 3);
    const middle = cleaned.substring(3, 6);
    const last = cleaned.substring(6, 10);

    let formattedNumber = '';
    if (cleaned.length > 6) {
      formattedNumber = `(${zip}) ${middle} - ${last}`;
    } else if (cleaned.length > 3) {
      formattedNumber = `(${zip}) ${middle}`;
    } else if (cleaned.length > 0) {
      formattedNumber = `(${zip}`;
    }
    this.setState({
      formattedProxy: formattedNumber
    });

    return formattedNumber;
  };

  render() {
    const {
      data: { error, loading }
    } = this.props;
    // const proxy = this.state.proxy;
    const formattedProxy = this.state.formattedProxy;
    const caseStatus = this.state.status;
    const assignmentStatus = this.state.active;
    console.log('Assignment Status', assignmentStatus);
    console.log('Case Status', caseStatus);
    let listWitnesses = this.props.data.listWitnesses && this.props.data.listWitnesses.items || []
    listWitnesses = listWitnesses.sort((a, b) => {
      return `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
    });

    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        <Dimmer.Dimmable active={loading}>
          <Table basic='very' column={6} fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Added to Case</Table.HeaderCell>
                <Table.HeaderCell>Primary Language</Table.HeaderCell>
                <Table.HeaderCell>Phone Type</Table.HeaderCell>
                <Table.HeaderCell>Preferred Contact Method</Table.HeaderCell>
                <Table.HeaderCell>
                  {/* Call */}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {formattedProxy && listWitnesses && listWitnesses.length > 0 ? (
                [...listWitnesses].map(item => (
                  <Table.Row key={item.id}>
                    <Table.Cell>
                      {item.lastName}, {item.firstName}
                      <div>
                        {item.trialWitness === "yes" ?
                          <Label color='red' basic size='tiny' horizontal>
                            Trial Witness
                        </Label>
                          : null}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {item.createdAt &&
                        moment(item.createdAt).format('MM/DD/YYYY')}
                    </Table.Cell>
                    <Table.Cell>{item.language}</Table.Cell>
                    <Table.Cell>
                      {this.upperCase(item.phoneNumbers[0].type)}
                    </Table.Cell>
                    <Table.Cell>
                      {this.upperCase(item.preferredContactMethod)}
                    </Table.Cell>
                    <Table.Cell>
                      {(assignmentStatus === true && caseStatus === 'active') ?
                        <ConfirmModal
                          type={'Call'}
                          title="Call Witness"
                          size="compact"
                          content={(
                            <span>
                              <p>Please dial your WitCom call-in number, <strong>{formattedProxy}</strong>, from the phone
                             you registered with WitCom to call witness <strong>{item.firstName} {item.lastName}.</strong></p>
                            </span>
                          )}
                          onChangePositive={() => this.placeCall(item)}
                          isDirty={true}
                          loading={false}
                        // disabled={(assignmentStatus === false || caseStatus === 'closed') ? true : false}
                        />
                        : null
                      }
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                  <Table.Row>
                    <Table.Cell colSpan='7' textAlign='center'>
                      {' '}
                      No witnesses available
                    {' '}
                    </Table.Cell>
                  </Table.Row>
                )}
            </Table.Body>
          </Table>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

WitnessTable.propTypes = {
  caseId: PropTypes.string.isRequired,
  data: PropTypes.object,
  callWitness: PropTypes.func.isRequired
};

WitnessTable.defaultProps = {
  data: {}
};

export default flowRight(
  graphqlMutation(CallWitness),
  graphql(ListWitnesses, {
    options: props => ({
      variables: {
        caseId: props.caseId
      }
    })
  })
)(WitnessTable);
