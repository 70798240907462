import gql from 'graphql-tag';

const DefenderFragment = gql`
  fragment DefenderFragment on Defender {
    id
    email
    firstName
    lastName
    phoneNumber
    company
    proxy
  }
`;

const GetDefender = gql`
  query($id: ID!) {
    getDefender(id: $id) {
      ...DefenderFragment
    }
  }
  ${DefenderFragment}
`;

const ListDefenders = gql`
  query($jurisdiction: String!) {
    listDefenders(jurisdiction: $jurisdiction) {
      items {
        id
        email
        firstName
        lastName
        enabled
        status
        phoneNumber
        company
      }
      nextToken
    }
  }
`;

const UpdateCaseDefenders = gql`
  mutation($caseId: ID!, $defenderIds: [ID!]!) {
    updateCaseDefenders(caseId: $caseId, defenderIds: $defenderIds) {
      items {
        active
        defender {
          id
          firstName
          lastName
          email
          phoneNumber
        }
      }
    }
  }
`;

const ListPhoneTypes = gql`
  query {
    __type(name: "PhoneType") {
      name
      enumValues {
        name
      }
    }
  }
`;

const CreateDefender = gql`
  mutation($defender: DefenderInput!) {
    createDefender(defender: $defender) {
      ...DefenderFragment
    }
  }
  ${DefenderFragment}
`;

const UpdateDefender = gql`
  mutation($id: ID!, $defender: DefenderInput!) {
    updateDefender(id: $id, defender: $defender) {
      ...DefenderFragment
    }
  }
  ${DefenderFragment}
`;

const DisableDefender = gql`
  mutation($id: ID!) {
    disableDefender(id: $id) {
      ...DefenderFragment
    }
  }
  ${DefenderFragment}
`;

const EnableDefender = gql`
  mutation($id: ID!) {
    enableDefender(id: $id) {
      ...DefenderFragment
    }
  }
  ${DefenderFragment}
`;

const OnDefenderSubscription = gql`
  subscription {
    onCreateDefender {
      ...DefenderFragment
    }
    onUpdateDefender {
      ...DefenderFragment
    }
  }
  ${DefenderFragment}
`;

const ListDefenderGroups = gql`
  query {
    __type(name: "DefenseGroup") {
      name
      enumValues {
        name
      }
    }
  }
`;

export {
  GetDefender,
  ListDefenders,
  EnableDefender,
  ListPhoneTypes,
  CreateDefender,
  UpdateDefender,
  DisableDefender,
  ListDefenderGroups,
  UpdateCaseDefenders,
  OnDefenderSubscription,
};
