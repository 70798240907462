import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { navigate } from '../services/RouteState';

import { isAuthenticated } from '../services/Authentication';

class PrivateRoute extends Component {
  state = {
    authenticated: false
  };

  async componentDidMount() {
    const {
      location: { pathname }
    } = this.props;

    try {
      await isAuthenticated();

      this.setState({
        authenticated: true
      });
    } catch (error) {
      console.log('Private route auth failed', error);
      console.log('Build me please');
      if (pathname !== '/login') {
        navigate('/app');
      }
    }
  }

  render() {
    const { authenticated } = this.state;
    const { component: Component, location, ...rest } = this.props;

    return <>{authenticated && <Component location={location} {...rest} />}</>;
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.object
};

PrivateRoute.defaultProps = {
  location: {}
};

export default PrivateRoute;
