import React, { Component } from 'react';

import { navigate } from '../services/RouteState';

import { isAuthenticated } from '../services/Authentication';

export default class Root extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    console.log(this.props.path);
    try {
      const response = await isAuthenticated();
      navigate('/app/home');
    } catch (error) {
      console.log('Root auth failed');

      navigate('/login');
    }
  }

  render() {
    return <></>;
  }
}
