import gql from 'graphql-tag';
// import { CaseFragment } from './CaseApi';

const WitnessFragment = gql`
  fragment WitnessFragment on Witness {
    caseId
    id
    firstName
    lastName
    trialWitness
    language
    phoneNumbers {
      type
    }
    createdAt
    preferredContactMethod
    phoneHash
  }
`;

const CaseFragment = gql`
  fragment CaseFragment on Case {
    createdAt
    arraignmentDate
    caseNumber
    indictmentNumber
    matterNumber
    defendantFirstName
    defendantLastName
    status
    jurisdiction
  }
`;

const ListWitnessCase = gql`
  query($phoneHash: String!) {
    listWitnessCase(phoneHash: $phoneHash) {
      items {
        witness {
          ...WitnessFragment
        }
        case {
          ...CaseFragment
        }
        active
      }
    }
  }
  ${WitnessFragment}
  ${CaseFragment}
`;

const GetWitness = gql`
  query($caseId: ID!, $id: ID!) {
    getWitness(caseId: $caseId, id: $id) {
      ...WitnessFragment
    }
  }
  ${WitnessFragment}
`;

const ListWitnesses = gql`
  query($caseId: ID!, $filter: WitnessFilter) {
    listWitnesses(caseId: $caseId, filter: $filter) {
      items {
        ...WitnessFragment
      }
      nextToken
    }
  }
  ${WitnessFragment}
`;

const MaskedNumberFragment = gql`
  fragment MaskedNumberFragment on MaskedNumber {
    phoneNumber
  }
`;

const CallWitness = gql`
  mutation($caseId: ID!, $witnessId: ID!) {
    callWitness(caseId: $caseId, witnessId: $witnessId) {
      ...MaskedNumberFragment
    }
  }
  ${MaskedNumberFragment}
`;

const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    createdAt
    text
    senderId
    phoneHash
  }
`;

const TextWitness = gql`
  mutation($message: String!, $phoneHash: String) {
    textWitness(message: $message, phoneHash: $phoneHash) {
      ...MessageFragment
    }
  }
  ${MessageFragment}
`;

const GetMessages = gql`
  query {
    getMessages {
      items {
        ...MessageFragment
      }
    }
  }
  ${MessageFragment}
`;

export {
  GetWitness,
  CallWitness,
  GetMessages,
  TextWitness,
  ListWitnesses,
  ListWitnessCase
};
