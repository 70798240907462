import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import {
  Breadcrumb,
  Button,
  Dimmer,
  Divider,
  Grid,
  Form,
  Loader,
  Message,
  Segment,
  Text,
} from 'semantic-ui-react';

import { GetDefender, CreateDefender, UpdateDefender } from './DefenderApi';
import SubmitModal from '../modal/SubmitModal';
import { errors, validate } from '../models/FieldValidator.js';
import { getUserAttributes } from '../../services/Authentication';

const styles = {
  errorText: {
    color: 'red',
    fontSize: '11px',
  },
};

class DefenderForm extends Component {
  // errorMessage;
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      isDirty: false,
      loading: false,
      error: null,
      defenderInput: {
        group: 'defender',
      },
      // initialDefender: null
    };
  }

  async componentDidMount() {
    const {
      data: { getDefender },
    } = this.props;

    console.log('CDM State', this.props)

    const attributes = await getUserAttributes();
    this.setState({
      jurisdiction: attributes.jurisdiction,
    });

    if (getDefender) {
      this.setState({
        edit: true,
        defenderInput: getDefender,
        // initialDefender: getDefender
      });
    }
    console.log('CDM State post getDefender', this.state)
  }

  componentDidUpdate() {
    const {
      data: { getDefender },
      location: {
        state: { defenderId },
      },
    } = this.props;
    const { defenderInput } = this.state;
    if (defenderId && getDefender && [...getDefender.id] && !defenderInput.id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        defenderInput: getDefender,
      });
    }
  }

  handleOnChange = event => {
    const { defenderInput } = this.state;

    defenderInput[event.target.name] = event.target.value;

    this.setState({
      defenderInput,
      isDirty: true,
    });
  };

  handlePhoneChange = (phone, name) => {
    const { defenderInput } = this.state;

    defenderInput[name] = phone;

    this.setState({
      defenderInput,
      isDirty: true,
    });
  };

  handleCancel = () => {
    // this.setState({
    //   defenderInput: initialDefender
    // });
    // console.log('Cancel', this.state)
    navigate('/app/home');
  };

  handleSubmit = async () => {
    const { edit, defenderInput, jurisdiction } = this.state;
    const { updateDefender } = this.props;
    this.setState({
      loading: true,
    });
    let response = null;
    defenderInput.company = defenderInput.company || '';
    if (
      defenderInput.email &&
      defenderInput.firstName &&
      // defenderInput.group &&
      defenderInput.lastName &&
      defenderInput.phoneNumber
    ) {
      try {
        {
          response = await updateDefender({
            id: defenderInput.id,
            defender: {
              email: defenderInput.email.toLowerCase(),
              firstName: defenderInput.firstName,
              // group: defenderInput.group,
              lastName: defenderInput.lastName,
              phoneNumber: defenderInput.phoneNumber,
              company: defenderInput.company,
              jurisdiction,
            },
          });
        }
        if (response.data.errors) {
          this.setState({
            error: response.data.errors[0],
            loading: false,
          });
          return;
        }

        const data = response.data.updateDefender;
        const params = {
          state: {
            active: edit ? defenderInput.enabled : true,
            defenderId: data.id
          },
        };
        console.log('Params', params)
        navigate('/app/home', params);
      } catch (errors) {
        console.log('Errors', errors);
        this.setState({
          loading: false,
          error: response.errors[0].message,
        });
      }
    } else {
      this.setState({
        loading: false,
        error: 'Please fill out all fields',
      });
    }
  };

  render() {
    const { edit, error, defenderInput, loading, isDirty } = this.state;
    return (
      <>
        <Grid columns="equal" relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <Breadcrumb size="huge">
                {/* <Breadcrumb.Section
                        link
                        onChangePositive
                        onClick={() => navigate('/app/defender')}>
                        Defense
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider /> */}
                <Breadcrumb.Section active>
                  {'Edit Phone Number'}
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <SubmitModal
                type="Cancel"
                title="Unsaved Changes"
                content={
                  'You are about to exit with unsaved changes.\xa0 Do you wish to proceed without saving?'
                }
                isDirty={isDirty}
                loading={loading}
                onChangePositive={() => this.handleCancel()}
                disabled={false}
                size="small"
              />
              <SubmitModal
                type="Submit"
                title={
                  'Change Phone Number'
                }
                content={
                  `You are about to change the phone number associated with this account.\xa0 Do you wish to proceed?`
                }
                loading={loading}
                size="small"
                disabled={
                  !isDirty ||
                  !defenderInput.email ||
                  !validate('email', defenderInput.email) ||
                  !defenderInput.firstName ||
                  defenderInput.firstName.length < 2 ||
                  !defenderInput.lastName ||
                  defenderInput.lastName.length < 2 ||
                  !defenderInput.phoneNumber ||
                  !validate('phoneNumber', defenderInput.phoneNumber)
                  // !defenderInput.group
                }
                isDirty={isDirty}
                onChangePositive={() => this.handleSubmit('active')}
              />
              <div style={{ paddingBottom: '25px' }} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Dimmer.Dimmable active={loading}>
          <Segment>
            <Form
              autoComplete="off"
              className="attached fluid"
              loading={loading}
            >
              {error && (
                <Message negative>
                  <p>{error}</p>
                </Message>
              )}
              {/* <Form.Field autoComplete="off">
                <Form.Input
                  label="First Name *"
                  autoComplete="off"
                  name="firstName"
                  onChange={this.handleOnChange}
                  disabled={true}
                  placeholder="First Name"
                  value={defenderInput.firstName}
                  error={
                    defenderInput.firstName ? (
                      !validate('firstName', defenderInput.firstName) ? (
                        <span
                          style={{
                            color: 'red',
                            fontSize: '11px',
                          }}
                        >
                          <text>{errors['firstName'].message}</text>
                        </span>
                      ) : (
                          undefined
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  label="Last Name *"
                  autoComplete="off"
                  name="lastName"
                  onChange={this.handleOnChange}
                  disabled={true}
                  placeholder="Last Name"
                  value={defenderInput.lastName}
                  error={
                    defenderInput.lastName ? (
                      !validate('lastName', defenderInput.lastName) ? (
                        <span
                          style={{
                            color: 'red',
                            fontSize: '11px',
                          }}
                        >
                          <text>{errors['lastName'].message}</text>
                        </span>
                      ) : (
                          undefined
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  label="Organization"
                  autoComplete="off"
                  name="company"
                  onChange={this.handleOnChange}
                  disabled={true}
                  placeholder="Organization"
                  value={defenderInput.company}
                // error={
                //   defenderInput.company ?
                //     (!validate('company', defenderInput.company) ? (
                //       <span
                //         style={{
                //           color: 'red',
                //           fontSize: '11px'
                //         }}>
                //         <text>{errors['company'].message}</text>
                //       </span>
                //     ) : undefined) : undefined
                // }
                />
              </Form.Field>
              <Form.Field autoComplete="off">
                <Form.Input
                  label="Email *"
                  autoComplete="off"
                  name="email"
                  value={defenderInput.email}
                  // onChange={value =>
                  //   this.isValidField(name, value)
                  // }
                  onChange={this.handleOnChange}
                  disabled={true}
                  placeholder="Email"
                  error={
                    defenderInput.email ? (
                      validate('email', defenderInput.email) ? (
                        undefined
                      ) : (
                          <span
                            style={{
                              color: 'red',
                              fontSize: '11px',
                            }}
                          >
                            <text>{errors['email'].message}</text>
                          </span>
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field> */}
              <Form.Field>
                {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                {defenderInput.phoneNumber &&
                  !validate('phoneNumber', defenderInput.phoneNumber) ? (
                    <label style={{ color: '#9F3A38' }}>Phone Number *</label>
                  ) : (
                    <label style={{ color: 'rgba(0,0,0,0.87)' }}>
                      Phone Number *
                  </label>
                  )}
                <PhoneInput
                  placeholder="Phone number"
                  autoComplete="off"
                  value={defenderInput.phoneNumber}
                  name="phoneNumber"
                  onChange={phone =>
                    this.handlePhoneChange(phone, 'phoneNumber')
                  }
                  showCountrySelect={false}
                  country="US"
                  // eslint-disable-next-line no-nested-ternary
                  error={
                    defenderInput.phoneNumber ? (
                      validate('phoneNumber', defenderInput.phoneNumber) ? (
                        undefined
                      ) : (
                          <span
                            style={{
                              color: 'red',
                              fontSize: '11px',
                            }}
                          >
                            <text>{errors['phoneNumber'].message}</text>
                          </span>
                        )
                    ) : (
                        undefined
                      )
                  }
                />
              </Form.Field>
              {/* <Form.Field autoComplete="off">
                                <label>Group</label>
                                <DefenderGroupSelect
                                    name="group"
                                    onChange={this.handleOnChange}
                                    value={defenderInput.group}
                                />
                            </Form.Field> */}
            </Form>
          </Segment>
          <Dimmer active={loading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </>
    );
  }
}

DefenderForm.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  createDefender: PropTypes.func.isRequired,
  updateDefender: PropTypes.func.isRequired,
};

DefenderForm.defaultProps = {
  data: {},
  location: {},
};

export default flowRight(
  graphql(GetDefender, {
    skip: props => {
      const {
        location: { state: { defenderId } = {} },
      } = props;
      return !defenderId;
    },
    options: props => {
      const {
        location: { state: { defenderId } = {} },
      } = props;
      return {
        variables: {
          id: defenderId,
        },
      };
    },
  }),
  graphqlMutation(CreateDefender),
  graphqlMutation(UpdateDefender)
)(DefenderForm);
