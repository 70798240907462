import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';
import { graphqlMutation } from 'aws-appsync-react';
import { navigate } from '../../services/RouteState';
import { Breadcrumb, Divider, Grid, Segment, Button, Icon } from 'semantic-ui-react';
import DefenderHeader from './DefenderHeader';
import IdentityTable from '../identity/identityTable';
import { getUserGroup, getUserAttributes } from '../../services/Authentication';

class DefenderView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async componentWillMount() {
    const role = await getUserGroup();
    this.setState({
      loading: true
    });

    try {
      const attributes = await getUserAttributes();
      this.setState({
        defenderId: attributes.sub,
        loading: false
      });
    } catch (error) {
      this.setState({
        error
      });
    }
  }

  handleOnClick = defenderId => {
    navigate('/app/defender/edit', {
      state: { defenderId }
    });
  };

  render() {
    const { defenderId, loading } = this.state;

    return (
      <>
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                <Breadcrumb.Section active>
                  Defense Attorney Information
                </Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              {
                <Button
                  onClick={() => this.handleOnClick(defenderId)}
                  color='blue'
                  compact
                  size='compact'
                  disabled={false}>
                  {/* <Icon color='white' name='edit' /> */}
                  Edit Phone Number
                </Button>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <DefenderHeader defenderId={defenderId} />
        <Divider hidden />
        <Segment>
          <IdentityTable defenderId={defenderId} />
        </Segment>
      </>
    );
  }
}

DefenderView.defaultProps = {
  location: {}
};

export default flowRight(
)(DefenderView);
