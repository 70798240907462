import gql from "graphql-tag";

const CaseFragment = gql`
  fragment CaseFragment on Case {
    id
    createdAt
    arraignmentDate
    caseNumber
    indictmentNumber
    matterNumber
    defendantFirstName
    defendantLastName
    status
    jurisdiction
  }
`;

const GetCase = gql`
  query($id: ID!) {
    getCase(id: $id) {
      ...CaseFragment
    }
  }
  ${CaseFragment}
`;

const ListCases = gql`
  query {
    listCases {
      items {
        active
        case {
          ...CaseFragment
        }
      }
    }
  }
  ${CaseFragment}
`;

export { GetCase, ListCases, CaseFragment };
