import React, { Component } from 'react';

import { navigate } from '../../services/RouteState';
import { Button, Form, Message } from 'semantic-ui-react';

import { forgotPassword } from '../../services/Authentication';
import { errors, validate } from '../models/FieldValidator.js';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
    };
    // bind function to component
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    // when component mounts, add a document listener for keydown
    document.addEventListener('keydown', this.handleKeyPress, false);
    this.setState({email: this.props.location.state.email});
  }

  componentWillUnmount() {
    // when component unmounts, remove document listener so there aren't any accidental fires in other components
    document.removeEventListener('keydown', this.handleKeyPress, false);
  }

  handleOnChange = event =>
    this.setState({
      [event.target.name]: event.target.value,
    });

  handleCancel = () => {
    navigate('login');
  };

  handleSubmit = async () => {
    const { email } = this.state;

    try {
      await forgotPassword({
        email: email.toLowerCase(),
      });

      navigate('/reset', { state: { email: email.toLowerCase() } });
    } catch (error) {
      this.setState({
        error,
      });
    }
  };

  handleKeyPress = event => {
    // check if keycode is 13 - 'enter'
    if (event.keyCode === 13) {
      // stop event from doing it's normal function
      event.preventDefault();
      // add submit function to 'enter'
      this.handleSubmit();
    }
  };

  render() {
    const { error, email } = this.state;

    return (
      <>
        <Form attached fluid segment inverted onK>
          {error && (
            <Message negative>
              <p style={{ textAlign: 'left' }}>
                Your password cannot be reset prior to initial login. Please use
                your temporary password to access the application.
              </p>
            </Message>
          )}
          <Form.Field>
            <Form.Input
              icon="mail"
              iconPosition="left"
              name="email"
              onChange={this.handleOnChange}
              placeholder="Email"
              value={email}
              error={
                email ? (
                  !validate('email', email) ? (
                    <span
                      style={{
                        color: 'red',
                        fontSize: '11px',
                      }}>
                      <span>{errors['email'].message}</span>
                    </span>
                  ) : undefined) : undefined

              }

            />
          </Form.Field>
          <Button floated="left" onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button
            disabled={!email}
            floated="left"
            onClick={this.handleSubmit}
            positive
          >
            Submit
          </Button>
        </Form>
      </>
    );
  }
}

export default ForgotPassword;
