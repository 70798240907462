import React from 'react';

import { Link } from 'gatsby';
import { Segment, Header, ButtonGroup, Button } from 'semantic-ui-react';

const subHeadStyles = {
  fontWeight: '600',
  marginTop: '30px',
};

const Terms = () => (
  <>
    <Segment textAlign="left" padded>
      <Header>Lex Loci Labs Terms of Service</Header>

      <span style={subHeadStyles}>1. Terms</span>
      <p>
        By accessing our app, WitCom, you are agreeing to be bound by these
        terms of service, all applicable laws and regulations, and agree that
        you are responsible for compliance with any applicable local laws. If
        you do not agree with any of these terms, you are prohibited from using
        or accessing WitCom. The materials contained in WitCom are protected by
        applicable copyright and trademark law.
      </p>

      <span style={subHeadStyles}>2. Use License</span>
      <p>
        Permission is granted to temporarily download one copy of WitCom per
        device for personal, non-commercial transitory viewing only. This is the
        grant of a license, not a transfer of title, and under this license you
        may not:
      </p>
      <ul>
        <li>modify or copy the materials</li>
        <li>
          use the materials for any commercial purpose, or for any public
          display (commercial or non-commercial)
        </li>
        <li>
          attempt to decompile or reverse engineer any software contained in
          WitCom
        </li>
        <li>
          remove any copyright or other proprietary notations from the materials
        </li>
        <li>
          transfer the materials to another person or "mirror" the materials on
          any other server
        </li>
      </ul>

      <span style={subHeadStyles}>3. Disclaimer</span>
      <p>
        The materials within WitCom are provided on an 'as is' basis. Lex Loci
        Labs makes no warranties, expressed or implied, and hereby disclaims and
        negates all other warranties including, without limitation, implied
        warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property or other violation
        of rights.
      </p>
      <p>
        Further, Lex Loci Labs does not warrant or make any representations
        concerning the accuracy, likely results, or reliability of the use of
        the materials on its website or otherwise relating to such materials or
        on any sites linked to WitCom.
      </p>

      <span style={subHeadStyles}>4. Limitations</span>
      <p>
        In no event shall Lex Loci Labs or its suppliers be liable for any
        damages (including, without limitation, damages for loss of data or
        profit, or due to business interruption) arising out of the use or
        inability to use WitCom, even if Lex Loci Labs or a Lex Loci Labs
        authorized representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow
        limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to
        you.
      </p>

      <span style={subHeadStyles}>5. Accuracy of materials</span>
      <p>
        The materials appearing in WitCom could include technical,
        typographical, or photographic errors. Lex Loci Labs does not warrant
        that any of the materials on WitCom are accurate, complete or current.
        Lex Loci Labs may make changes to the materials contained in WitCom at
        any time without notice. However Lex Loci Labs does not make any
        commitment to update the materials.
      </p>

      <span style={subHeadStyles}>6. Links</span>
      <p>
        Lex Loci Labs has not reviewed all of the sites linked to its app and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by Lex Loci Labs of the site. Use
        of any such linked website is at the user's own risk.
      </p>

      <span style={subHeadStyles}>7. Modifications</span>
      <p>
        Lex Loci Labs may revise these terms of service for its app at any time
        without notice. By using WitCom you are agreeing to be bound by the then
        current version of these terms of service.
      </p>

      <span style={subHeadStyles}>8. Governing Law</span>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of North Carolina and you irrevocably submit to the
        exclusive jurisdiction of the courts in that State or location.
      </p>

      <ButtonGroup>
        <Link as="button" to="/login">
          <Button>Back</Button>
        </Link>
      </ButtonGroup>
    </Segment>
  </>
);
export default Terms;
