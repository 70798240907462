// import Cases from './case/Cases';
// import CaseForm from './case/CaseForm';
import CaseView from './case/CaseView';
// import IdentityTable from './identity/identityTable';
// import IdentityTableSelf from './identity/identityTableSelf';
// import Defenders from './defender/Defenders';
import DefenderForm from './defender/DefenderForm';
import DefenderView from './defender/DefenderView';
// import Profile from './profile/Profile';
// import Prosecutors from './prosecutors/Prosecutors';
// import ProsecutorForm from './prosecutors/ProsecutorForm';
// import ProsecutorView from './prosecutors/ProsecutorView';
// import SearchCasesForm from './search/searchCasesForm';

const Routes = [
  {
    navTitles: [
      // {
      //   name: 'case',
      //   title: 'Cases',
      //   group: ['defender']
      // },
      // {
      //   name: 'prosecution',
      //   title: 'Prosecutors',
      //   group: ['defender']      },
      {
        name: 'defense',
        title: 'Defense Attorney',
        group: ['defender']
      }
    ],
    route: [
      {
        path: '/home',
        name: 'viewdefender',
        parent: 'defense',
        variable: '',
        nav: true,
        // mini: 'B',
        component: DefenderView,
        title: 'Dashboard',
        group: 'defender'
      },
      // {
      //   path: '/home',
      //   name: 'my-cases',
      //   variable: 'self',
      //   parent: 'case',
      //   nav: true,
      //   mini: 'B',
      //   component: IdentityTableSelf,
      //   title: 'My Cases',
      //   group: 'both',
      //   icon: 'archive'
      // },
      // {
      //   path: '/findcase',
      //   name: 'find case',
      //   variable: 'self',
      //   parent: 'case',
      //   nav: true,
      //   mini: 'B',
      //   component: Cases,
      //   title: 'find case',
      //   group: 'special_prosecutor',
      //   icon: 'search'
      // },
      // {
      //   path: '/case',
      //   name: 'opencases',
      //   parent: 'case',
      //   variable: 'active',
      //   nav: false,
      //   mini: 'B',
      //   component: Cases,
      //   title: 'Cases',
      //   group: 'both'
      // },
      // {
      //   path: '/case/new',
      //   name: 'editcase',
      //   parent: 'case',
      //   variable: '',
      //   mini: 'B',
      //   nav: true,
      //   component: CaseForm,
      //   title: 'Create Case',
      //   group: 'both',
      //   icon: 'plus circle'
      // },
      // {
      //   path: '/case/edit',
      //   name: 'editcase',
      //   parent: 'case',
      //   variable: '',
      //   mini: 'B',
      //   nav: false,
      //   component: CaseForm,
      //   title: 'Edit Case',
      //   group: 'both',
      //   icon: 'file outline'
      // },
      {
        path: '/case/view',
        name: 'viewcase',
        parent: 'case',
        variable: '',
        nav: false,
        mini: 'B',
        component: CaseView,
        group: 'both'
      },
      // {
      //   path: '/defender',
      //   name: 'defenders',
      //   parent: 'defense',
      //   variable: '',
      //   nav: true,
      //   mini: 'B',
      //   component: Defenders,
      //   title: 'Defense Attorney List',
      //   group: 'both',
      //   icon: 'list'
      // },
      {
        path: '/defender/edit',
        name: 'editdefender',
        parent: 'defense',
        variable: '',
        nav: false,
        mini: 'B',
        component: DefenderForm,
        title: 'Edit Phone Number',
        group: 'both',
        icon: 'user plus'
      },
      {
        path: '/defender/view',
        name: 'viewdefender',
        parent: 'defender',
        variable: '',
        nav: false,
        mini: 'B',
        component: DefenderView,
        title: 'View Defender',
        group: 'both'
      },
      // {
      //   path: '/profile',
      //   name: 'profile',
      //   parent: 'user',
      //   variable: '',
      //   nav: false,
      //   mini: 'B',
      //   component: Profile,
      //   group: 'both'
      // },
      // {
      //   path: '/prosecutor',
      //   name: 'prosecutors',
      //   parent: 'prosecution',
      //   variable: '',
      //   nav: true,
      //   mini: 'B',
      //   component: Prosecutors,
      //   title: 'Prosecutor List',
      //   group: 'both',
      //   icon: 'list'
      // },
      // {
      //   path: '/prosecutor/edit',
      //   name: 'editprosecutor',
      //   parent: 'prosecution',
      //   variable: '',
      //   nav: true,
      //   mini: 'B',
      //   component: ProsecutorForm,
      //   title: 'Register Prosecutor',
      //   group: 'admin',
      //   icon: 'user plus'
      // },
      // {
      //   path: '/prosecutor/view',
      //   name: 'viewprosecutor',
      //   parent: 'prosecution',
      //   variable: '',
      //   nav: false,
      //   mini: 'B',
      //   component: ProsecutorView,
      //   title: 'View Prosecutor',
      //   group: 'both'
      // }
    ]
  }
];

export default Routes;
