import React from 'react';
import PropTypes from 'prop-types';

import { Divider, Header, HeaderSubheader } from 'semantic-ui-react';

import WitnessTable from './WitnessTable';

const Witnesses = ({ caseId, active, status }) => (
    <>
        <Header color='teal' as='h4'>
            Witnesses
        </Header>
        <HeaderSubheader>
        Select a witness to call from the list below. Then, dial your WitCom call-in number from your registered phone to place a call the selected witness.
        </HeaderSubheader>
        <Divider hidden />
        <WitnessTable caseId={caseId} active={active} status={status} />
    </>
);

Witnesses.propTypes = {
    caseId: PropTypes.string.isRequired,
    active: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

export default Witnesses;
