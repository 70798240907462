import React from 'react';
import 'isomorphic-fetch';
import { Router } from '@reach/router';
import AWSAppSyncClient from 'aws-appsync';
import { ApolloProvider } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react';

import Terms from '../components/authentication/Tos';
import PublicLayout from '../components/PublicLayout';
import Login from '../components/authentication/Login';
import PrivateLayout from '../components/PrivateLayout';
import Privacy from '../components/authentication/Privacy';
import { getAccessToken } from '../services/Authentication';
import ResetPassword from '../components/authentication/ResetPassword';
import ChangePassword from '../components/authentication/ChangePassword';
import ForgotPassword from '../components/authentication/ForgotPassword';
import Root from '../components/Root';

import 'semantic-ui-less/semantic.less';

const client = new AWSAppSyncClient(
  {
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () => getAccessToken()
    },
    region: 'us-east-1',
    url: process.env.APPSYNC_API_ENDPOINT
  },
  {
    connectToDevTools: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  }
);

const Index = () => {
  // Using <PublicLayout path='/*' /> with nested routes causes the public login router to trigger.
  // The reason is /app/* is matched first by the /* and so it renders the public route and then
  // the app path. Reach Router behaves differently than React Router in this regard. A quick fix is it
  // match the paths here for public routes.
  return (
    <Router>
      <PublicLayout default path='/' component={Root} />
      <PublicLayout path='/login' component={Login} />
      <PublicLayout path='/change' component={ChangePassword} />
      <PublicLayout path='/forgot' component={ForgotPassword} />
      <PublicLayout path='/reset' component={ResetPassword} />
      <PublicLayout path='/tos' component={Terms} width={14} />
      <PublicLayout path='/privacy' component={Privacy} width={14} />
      <PrivateLayout path='/app/*' />
    </Router>
  );
};

const WithProvider = props => {
  return (
    <ApolloProvider client={client}>
      <Rehydrated>
        <Index />
      </Rehydrated>
    </ApolloProvider>
  );
};

export default WithProvider;
