import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { buildSubscription } from 'aws-appsync';
import moment from 'moment';
import { graphql } from 'react-apollo';
import {
  Dimmer,
  Icon,
  Grid,
  Header,
  Loader,
  Message,
  Divider,
  Segment
} from 'semantic-ui-react';

import { GetDefender, OnDefenderSubscription } from './DefenderApi';
import { getUserAttributes } from '../../services/Authentication';
import { formatPhoneNumber } from 'react-phone-number-input';

class DefenderHeader extends Component {
  componentDidMount() {
    const { defenderId } = this.props;
    if (defenderId) {
      const {
        data: { subscribeToMore }
      } = this.props;
      subscribeToMore(buildSubscription(OnDefenderSubscription, GetDefender));
      console.log('Class Defender', this.props);
    }
  }

  upperCase = string => string.charAt(0).toUpperCase() + string.slice(1);

  render() {
    const {
      data: { error, loading, getDefender }
    } = this.props;
    console.log('Get Defender', getDefender);
    return (
      <>
        {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )}
        {getDefender && (
          <div style={{ backgroundColor: '#fff' }}>
            <Segment>
              <Dimmer.Dimmable dimmed={loading}>
                <Grid
                  columns='equal'
                  relaxed={false}
                  stretched
                  stackable
                  divided
                >
                  <Grid.Row>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {`${getDefender.lastName}, ${getDefender.firstName}`}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='linkify' />
                            Name
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {getDefender.company
                            ? this.upperCase(getDefender.company)
                            : '-'}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='building outline' />
                            Organization
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content
                          style={{
                            wordBreak: 'break-all'
                          }}
                        >
                          {getDefender.email}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='mail' />
                            Email
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {formatPhoneNumber(getDefender.phoneNumber)}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='phone' />
                            Phone Number
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {formatPhoneNumber(getDefender.proxy)}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='phone' />
                            Witcom Number
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column>
                    {/* <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {formatPhoneNumber(getDefender.proxy)}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='mobile' />
                            WitCom Number
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column> */}
                    {/* <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {moment(getDefender.createdAt).format('MM/DD/YYYY')}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='clock outline' />
                            User Start Date
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column> */}

                    {/* <Grid.Column textAlign='center'>
                      <Header as='h4'>
                        <Header.Content>
                          {getDefender.enabled === true
                            ? getDefender.status === 'FORCE_CHANGE_PASSWORD'
                              ? 'Registered, Not Confirmed'
                              : 'Active'
                            : 'Disabled'}
                          <Divider hidden />
                          <Header.Subheader>
                            <Icon name='settings' />
                            Account Status
                          </Header.Subheader>
                        </Header.Content>
                      </Header>
                    </Grid.Column> */}
                  </Grid.Row>
                </Grid>
                <Dimmer active={loading} inverted>
                  <Loader />
                </Dimmer>
              </Dimmer.Dimmable>
            </Segment>
          </div>
        )}
      </>
    );
  }
}

DefenderHeader.propTypes = {
  data: PropTypes.object,
  defenderId: PropTypes.string
};

DefenderHeader.defaultProps = {
  data: {},
  defenderId: null
};

export default graphql(GetDefender, {
  skip: props => !props.defenderId,
  options: props => ({
    variables: {
      id: props.defenderId
    }
  })
})(DefenderHeader);
