import React, { Component } from 'react';

import { Router } from '@reach/router';
import { Link } from 'gatsby';
import { Container, Menu } from 'semantic-ui-react';

import Header from './Header';
import PrivateRoute from './PrivateRoute';
import Routes from './Route';
import IdentityTable from './identity/identityTable';

import '../styles/SideBar.css';
import '../styles/Form.css';
import '../styles/Header.css';
import '../styles/PhoneInputStyle.css';
import '../styles/Table.css';

import 'react-table/react-table.css';
import '../styles/ReactTableCustom.css';

import logo from '../images/logo.png';

import RouteState, { navigate } from '../services/RouteState';
import { getUserGroup, getUserAttributes } from '../services/Authentication';
import { UserProvider } from './context/UserContext';

class PrivateLayout extends Component {
  constructor(props) {
    super(props);
    let path = props.location.pathname.slice(4);

    if (path === '') {
      path = '/home';
    }

    const item = Routes[0].route.filter(r => {
      return path === r.path;
    });

    this.state = {
      activeItem: item[0].title,
      group: '',
      width: 210,
      height: 500,
      jurisdiction: '',
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    const group = await getUserGroup();
    const user = await getUserAttributes();
    this.setState({
      user: user,
      group: group,
    });
    RouteState.loadState();
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.props['*'] !== prevProps['*']) {
      // find matching path in route and set active Item accordingly
      // eslint-disable-next-line react/destructuring-assignment
      const response = Routes[0].route.find(
        item => item.path.substring(1) === this.props['*']
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        activeItem: response.title,
      });
    }
  }

  componentWillUnmount() {
    console.log('unmounting');
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  onChange = () => {
    const { width } = this.state;
    let newWidth = 210;
    if (width === 210) {
      newWidth = 0;
    }
    this.setState({
      width: newWidth,
    });
  };

  handleItemClick = (path, name) => {
    this.setState({ activeItem: name });
    navigate(`/app${path}`);
  };

  updateWindowDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  singleLetter = string => string.charAt(0);

  render() {
    const { user, activeItem, group, width, height, jurisdiction } = this.state;
    const highlight = {
      padding: '20px 0px 20px 15px',
      marginRight: '0px',
      fontSize: '13px',
      minHeight: '20px',
      color: 'rgba(255, 255, 255, 0.6)',
    };
    const highlights = {
      borderRight: '3px inset #5df2cf',
      padding: '19.5px 0px 19.5px 15px',
      marginRight: '0px',
      fontSize: '13px',
      color: '#5df2cf',
      backgroundColor: '#2f2d2d',
      borderRadius: '0px',
      // borderBottom: '1px inset grey',
      // borderTop: '1px inset grey',
    };

    return (
      <>
        {user && (
          <UserProvider value={{ user }}>
            <div
              style={{
                display: 'flex',
                minWidth: '90vw',
                minHeight: '100vh',
                flexDirection: 'column',
                overflowX: 'hidden',
                backgroundColor: '#f4f4f4',
              }}
            >
              <Header
                onChange={this.onChange}
                siteTitle="WitCom"
                width={width}
              />
              <div
                style={{
                  position: 'fixed',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '3.6em',
                  top: '-50px',
                  bottom: '0',
                  left: '0',
                  width: `${width}px`,
                  background: '#404040',
                  overflowX: 'hidden',
                  flex: '1',
                  transition: 'width 0.5s',
                }}
              >
                <div
                  style={{
                    flex: '1',
                    // eslint-disable-next-line no-nested-ternary
                    overflowY:
                      group === 'admin'
                        ? height > 553
                          ? 'hidden'
                          : 'scroll'
                        : height > 255
                        ? 'hidden'
                        : 'scroll',
                  }}
                >
                  <div
                    style={{
                      width: '210px',
                      margin: '0px',
                      padding: '0px',
                      height: '50px',
                      textAlign: 'center',
                      backgroundColor: '#383838',
                    }}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        height: '100%',
                        verticalAlign: 'middle',
                      }}
                    />
                    <img
                      src={logo}
                      style={{
                        maxHeight: '31px',
                        maxWidth: '125px !important',
                        verticalAlign: 'middle',
                      }}
                      alt="Brooklyn DA"
                    />
                  </div>
                  <Menu
                    inverted
                    vertical
                    size="large"
                    fluid
                    color="grey"
                    style={{
                      margin: '0px',
                    }}
                  >
                    {/* <Menu.Item
                                    as={Link}
                                    to='/app/home'
                                    name={'find case'}
                                    onClick={this.handleItemClick}
                                    style={
                                        activeItem === 'find case'
                                            ? highlights
                                            : highlight
                                    }
                                    icon='search'
                                    vertically='true'
                                    color='teal'
                                /> */}
                    {Routes[0].navTitles
                      .filter(item => {
                        return item.group.includes(group);
                      })
                      .map((item, index) => (
                        <>
                          <div
                            style={{
                              color: '#fff',
                              padding: '10px 0px 15px 15px',
                              borderTop: '1px solid rgba(255, 255, 255, 0.1)',
                            }}
                            key={index}
                          >
                            <span
                              style={{
                                fontSize: '12px',
                                color: 'rgba(255, 255, 255, 0.8)',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                            >
                              {item.title}
                            </span>
                          </div>
                          {Routes[0].route
                            .filter(
                              route =>
                                route.parent === item.name &&
                                route.nav &&
                                (route.group === group ||
                                  route.group === 'both' ||
                                  (route.group === 'special_prosecutor' &&
                                    !(
                                      jurisdiction === 'newyork-ny' &&
                                      group === 'prosecutor'
                                    )))
                            )
                            .map((route, index) => (
                              <>
                                <Menu.Item
                                  key={index}
                                  // as={Link}
                                  // to={`/app${
                                  //     route.path
                                  // }`}
                                  state={{
                                    type: route.variable,
                                  }}
                                  color="teal"
                                  // path={route.path}
                                  name={route.title}
                                  // active={activeItem === route.title}
                                  onClick={(evt, { name }) => {
                                    this.handleItemClick(route.path, name);
                                  }}
                                  style={
                                    activeItem === route.title
                                      ? highlights
                                      : highlight
                                  }
                                  icon={route.icon}
                                  className="sidebarItem"
                                />
                              </>
                            ))}
                        </>
                      ))}
                  </Menu>
                </div>
              </div>
              <div
                style={{
                  marginLeft: `${width}px`,
                  minWidth: '550px',
                  maxWidth: '100vw',
                  transition: 'margin-left 0.5s',
                }}
              >
                <Container
                  fluid
                  style={{
                    flex: 1,
                    marginTop: '5.25em',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                  }}
                >
                  <Router>
                    <PrivateRoute
                      default
                      path="/app"
                      component={IdentityTable}
                    />
                    {Routes[0].route
                      .filter(
                        route =>
                          route.group === group ||
                          route.group === 'both' ||
                          (route.group === 'special_prosecutor' &&
                            !(
                              jurisdiction === 'newyork-ny' &&
                              group === 'prosecutor'
                            ))
                      )
                      .map((item, index) => {
                        return (
                          <PrivateRoute
                            key={index}
                            path={item.path}
                            component={item.component}
                          />
                        );
                      })}
                  </Router>
                </Container>
              </div>
            </div>
            {/* <Footer /> */}
          </UserProvider>
        )}
      </>
    );
  }
}

export default PrivateLayout;
