import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  Message,
  Header,
  Segment,
  Icon
} from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import { flowRight } from 'lodash';
import { GetCase } from './CaseApi';
import CaseHeader from './CaseHeader';
import Witnesses from '../witness/Witnesses';
import { navigate } from '../../services/RouteState';

class CaseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false
    };
  }

  returnOnClick = () => {
    navigate('/home');
  };

  render() {
    const {
      location: { state: { caseId, active, status, pathFrom } = {} },
      data: { getCase }
    } = this.props;
    const { error, loading } = this.state;

    return (
      <>
        {/* {error && (
          <Message negative>
            <p>{error.message}</p>
          </Message>
        )} */}
        <Grid columns='equal' relaxed={false}>
          <Grid.Row>
            <Grid.Column textAlign='left'>
              <Breadcrumb size='huge'>
                <Breadcrumb.Section active>Case Information</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <CaseHeader caseId={caseId} />
        <Segment>
          <Witnesses caseId={caseId} active={active} status={status} />
        </Segment>
        <div style={{ paddingBottom: '50px' }} />
      </>
    );
  }
}

CaseView.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
};

CaseView.defaultProps = {
  location: {}
};

export default flowRight(
  graphql(GetCase, {
    options: props => {
      return {
        variables: { id: props.location.state.caseId }
      };
    }
  })
)(CaseView);
