import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

class ConfirmModal extends Component {
  state = {
    open: false
  };

  show = size => () => this.setState({ size, open: true });

  close = () => this.setState({ open: false });

  handlePositive = () => {
    const { onChangePositive } = this.props;
    this.setState({
      open: true,
      size: 'tiny'
    });

    onChangePositive();
  };

  handleClose = () => {
    const { onClose } = this.props;

    onClose();

    this.setState({
      open: false
    });
  };

  render() {
    const { open, size } = this.state;
    const {
      isDirty,
      title,
      type,
      disabled,
      content,
      onClose,
      loading
    } = this.props;

    return (
      <>
        <Button
          onClick={this.handlePositive}
          positive={type === 'Call' || false}
          disabled={disabled}
          loading={loading}
          icon
          style={{ paddingLeft: '15px', paddingRight: '15px' }}
          size='compact'
          color={
            type === 'Call'
              ? 'green'
              : null
          }>
          {type}
        </Button>

        <Modal size={size} open={open} onClose={this.close}>
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>{content}</Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.close}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
ConfirmModal.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onChangePositive: PropTypes.func.isRequired,
  // onChangeNegative: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  isDirty: PropTypes.bool.isRequired
};

ConfirmModal.defaultProps = {
  // onChangeNegative: null,
  loading: false
};

export default ConfirmModal;
